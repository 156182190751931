import React from "react"
import EmbedSlidesPresentation from "../../components/EmbedSlidesPresentation/EmbedSlidesPresentation"
import SlidesPresentation from "../../templates/slides-presentation"

const InhouseRecruitmentEng = (): React.ReactElement => {
  return (
    <SlidesPresentation>
      <EmbedSlidesPresentation iframeSrc="https://slides.com/bramtierie/flipbase-integrated-asynchronous-video-interaction-inhouse-recruitment-eng/embed" />
    </SlidesPresentation>
  )
}

export default InhouseRecruitmentEng
